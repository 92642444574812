@import "../../app/variables";

.topSide {
  display: flex;
  align-items: center;
  margin-bottom: 19px;
}

.values {
  color: $color-grey;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
}

.label {
  color: $color-grey-1;
  font-size: 12px;
  line-height: 18px;
  white-space: nowrap;
}

.arrow {
  width: 40px;
  height: 37px;
  margin-right: 7px;

  use {
    fill: $color-green;
  }
}

.arrowDown {
  transform: rotate(180deg);

  use {
    fill: $color-red;
  }
}
