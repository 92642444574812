@import "./../../app/variables.scss";

.pagination {
  display: flex;
  border: 1px solid #cdcdcd;
  border-radius: 0;
  text-align: center;
  align-items: center;
}

.button {
  display: block;
  float: left;
  margin: 10px;
  outline: none;
  color: $color-teal !important;
  vertical-align: middle;
  text-align: center;
  text-decoration: none;
  font-weight: bold;
}

.button.disabled {
  color: $color-grey-smooth;
  border-color: currentColor;
}

.pagination .currentPage {
  flex: 1;
  display: flex;
  white-space: nowrap;
  justify-content: center;
  align-items: center;
  float: left;
  margin: 0;
  padding: 0;
  width: 120px;
  height: 40px;
  vertical-align: middle;
  text-align: center;
  color: #66707d;
}

.pagination .separator {
  flex: 1 1 100%;
}

.pagination .input {
  margin-left: 10px;
  margin-right: 10px;
  max-width: 60px;
  flex: 1 0 60px;
  height: 36px;
  border: 1px solid #66707d;
  border-radius: 3px;
  outline: none;
  vertical-align: middle;
  text-align: center;
  color: #66707d;
}

.pagination .big {
  min-width: 50px;
}
