@import '~app/mixins';

.dropdown {
  position: relative;
  width: 100%;

  .iconCaretDown {
    width: 14px;
    height: 9px;
    position: absolute;
    top: 50%;
    right: 9px;
    transform: translateY(-50%);
    pointer-events: none;
  }

  .control {
    height: 26px;
    width: 100%;
    border: 1px solid $color-grey;
    color: #9d9d9d;
    border-radius: 4px;
    background: $color-white;
    padding: 4px 26px 4px 6px;
    outline: 0;
    font-size: 12px;
    line-height: 16px;
    -webkit-appearance: none;
    -moz-appearance: none;

    &::-ms-expand {
      display: none;
    }
  }
}

.checkboxDropdown {
  position: relative;

  .filter {
    height: 26px;
    border: 1px solid $color-grey;
    border-radius: 4px;
    background: $color-white;
    padding: 6px 35px 6px 12px;
    transition: all $transition-delay;
    color: $color-grey;
    font-size: 14px;
    cursor: pointer;
    display: flex;
    align-items: center;

    &.small {
      font-size: 12px;
      height: 26px;

      .iconCaretDown {
        top: 9px;
      }
    }

    &:focus {
      background: $color-white;

      @include primary-color-box-shadow-inset();
    }

    .value {
      @include text-overflow();
    }

    .icons {
      display: flex;

      > div {
        display: flex;

        svg {
          width: 16px;
          height: 16px;
          margin-top: 3px;

          use {
            fill: $color-grey-light;
          }
        }

        .sep {
          margin: 5px;
        }

        &:last-child .sep {
          display: none;
        }
      }
    }
  }

  .iconCaretDown {
    width: 12px;
    height: 9px;
    position: absolute;
    right: 9px;
  }

  .dropdown {
    $sizeArrow: 5px;

    position: absolute;
    right: 0;
    width: auto;
    border: 2px solid transparent;
    background: $color-white;
    box-shadow: $dropdown-shadow;
    border-radius: 2px;
    padding: 8px;
    display: none;
    z-index: 2;
    flex-direction: column;

    .groupContainer {
      &.unpinned {
        overflow: auto;
      }

      .group {
        .groupHeader {
          border-bottom: 1px solid $color-grey-light;
          padding-bottom: 2px;
          margin-bottom: 4px;
        }

        + .group {
          margin-top: 8px;
        }
      }

      + .groupContainer {
        margin-top: 8px;
      }
    }

    .search {
      border-bottom: 1px solid $color-grey-lighter;
      margin-bottom: 6px;
      padding-bottom: 6px;

      input {
        padding-left: 8px;
        height: 20px;
        border-color: $color-grey-light;
        border-radius: 10px;
      }
    }

    .noResults {
      margin-top: 8px;
      font-style: italic;
    }

    label span {
      padding-left: 3px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .modes {
      border-bottom: 1px solid $color-grey-lighter;
      margin-bottom: 6px;
      padding-bottom: 4px;

      .caption {
        pointer-events: none;
        text-align: center;
        font-weight: bold;
      }

      .items {
        display: flex;
        justify-content: center;

        > div {
          label span {
            padding-left: 3px;
          }

          + div {
            margin-left: 6px;
          }
        }
      }
    }

    @include primary-border-color();

    &:before,
    &:after {
      content: '';
      position: absolute;
    }

    &:before {
      right: 40px;
      width: $sizeArrow;
      height: $sizeArrow;
      transform: rotate(45deg);
      background: $color-white;

      @include primary-color-box-shadow();
    }

    &:after {
      height: $sizeArrow+2;
      width: 15px;
      right: 35px;
      background: $color-white;
    }

    &.top {
      margin-bottom: 5px;
      bottom: 100%;

      &:before {
        bottom: -3px;
      }

      &:after {
        bottom: 0;
      }
    }

    &:not(.top) {
      margin-top: 5px;
      top: 100%;

      &:before {
        top: -3px;
      }

      &:after {
        top: 0;
      }
    }

    &.open {
      display: flex;
    }
  }

  .rowRange {
    margin-top: 26px;
  }

  .rowRadio {
    margin-top: 40px;
  }

  .range {
    display: flex;
    align-items: center;
    width: 100%;

    .divider {
      color: $color-grey-1;
      font-size: 12px;
      line-height: 18px;
      padding: 0 10px;
    }
  }

  .dropdownPrice {
    width: 100%;
  }
}
