@import "~app/mixins";

.searchItem {
  height: 64px;
  width: 116px;
  border-radius: 4px;
  border-width: 0;
  background: $color-white;
  box-shadow: 0 1px 4px 0 rgba(140, 140, 140, 0.5);
  text-align: center;
  overflow: hidden;
  position: relative;

  &[disabled] {
    background: $color-grey-lighter;
  }

  &:not([disabled]) {
    &.active,
    &:active {
      background: $color-alabaster;
      box-shadow: 0 0 0 1px #d8d8df;
    }

    &.active,
    &:focus {
      @include primary-color-box-shadow('1px', '4px');
    }

    &:hover {
      background: $color-light-blue;

      @include primary-color-box-shadow('1px');
    }

    .value {
      @include primary-color();
    }

    cursor: pointer;
  }

  > * {
    max-width: 100%;
  }

  > div {
    width: 100%;
  }

  .searchInner {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .value {
      color: $color-teal !important;
      font-size: 28px;
      margin-top: 6px;
    }

    .label {
      color: #6b7581;
      font-size: 12px;
      height: 35px;
      vertical-align: middle;
      display: flex;
      justify-content: center;
      align-items: flex-start;
    }
  }
}

.loading {
  > div:first-child {
    > div {
      > div:first-child {
        height: 35px;

        svg {
          width: 40px;
          height: 35px;
        }
      }
    }
  }
}

//<div class="src-components-Loader-style__2X9Dw__loader">
//<div class="src-components-Loader-style__30K5k__wrapper">
//<div class="src-components-Loader-style__AzWJk__spinner">
//<svg class="icon-spinner">
//<use xlink:href="#spinner"></use></svg></div><div class="src-components-Loader-style__1imbs__text"><span>Loading</span></div></div></div>
//
