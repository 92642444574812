@import "./../../../app/variables.scss";

.formControl {
  textarea,
  select,
  input:not([type="checkbox"]),
  input:not([type="radio"]) {
    width: 100%;
    height: 26px;
    border: 1px solid $color-grey;
    color: #9d9d9d;
    border-radius: 4px;
    background: $color-white;
    padding: 5px;
    outline: 0;
    font-size: 12px;
    resize: none;

    &::-ms-expand {
      display: none;
    }
  }

  textarea {
    height: auto;
  }

  &.mb {
    margin-bottom: 15px;
  }
}

.label {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 3px;
  color: $color-blue-darkest;
  font-size: 12px;
  line-height: 14px;
}

.large {
  .label {
    color: $color-grey;
    font-size: 14px;
    font-weight: 500;
    line-height: 19px;
  }

  textarea,
  select,
  input:not([type="checkbox"]),
  input:not([type="radio"]) {
    height: 42px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: inset 0 1px 0 0 #ececec;
    color: #4a4a4a;
    font-size: 14px;
    line-height: 20px;
    padding-left: 15px;
  }

  textarea {
    height: auto;
  }
}

.large .label:empty,
.label:empty {
  display: none;

  &.showEmptyLabel {
    display: inline-block;
  }
}
