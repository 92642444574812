@import "./../../../app/variables.scss";

.checkbox {
  cursor: default;
  user-select: none;
  padding: 3px;

  .input {
    display: none;

    &:checked ~ .iconCheckboxChecked {
      display: block;
    }

    &:checked ~ .iconCheckbox {
      display: none;
    }

    &:disabled ~ .iconCheckbox,
    &:disabled ~ .iconCheckboxChecked {
      cursor: not-allowed;
      opacity: 0.5;
      background-color: $color-grey-light;
    }
  }

  .iconCheckbox,
  .iconCheckboxChecked {
    flex: 15px 0 0;
    width: 15px;
    height: 15px;
  }

  .iconCheckboxChecked {
    display: none;
  }

  .iconLabel {
    width: 16px;
    height: 16px;
    margin: 5px 5px -3px -4px;
  }

  .color {
    margin-left: 6px;
    margin-right: -4px;
    width: 14px;
    height: 9px;
  }

  .switch {
    display: flex;
    align-items: center;

    .label {
      color: $color-grey-1;
      font-size: 12px;
      padding-left: 10px;

      &:empty {
        display: none;
      }
    }
  }
}
