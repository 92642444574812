.btn_xl {
    padding: 13px 35px;
    min-width: 211px;
    min-height: 44px;
    border-radius: 999px;
    border: none;
    font-size: 18px;
    font-weight: 600;
    line-height: 18px;
    color: #FFFFFF;
}

.clsBtnPrimaryGreen {
    background-color: #128292 !important;
}
  
.btn_primary {
    padding: 9px 15px;
    background-color: transparent;
    border-radius: 999px;
    font-size: 14px;
    font-weight: 600;
    line-height: 14px;
    color: #FFFFFF;
    border: none;
}

.clsBtnDanger {
    background-color: #CA1551 !important;
  }
  
  .clsBtnOrng {
    background-color: #F16722 !important;
  }
  
  .clsBtnGray {
    background-color: #99A0AA !important;
  }
  
  .clsBtnWhite {
    background-color: #fff !important;
  }


  .clsInnerFooter {
    padding: 24px;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #EFF2F6; /* Matches the form background */
    border-top: 1px solid #ccc;
    display: flex;
    align-items: center;
    justify-content: center; /* Adjusts button alignment */
    box-shadow: inset 1px 0 0px 1px #BCC1C7; /* Subtle shadow for separation */

    button {
      + {
        button {
          margin-left: 24px;
        }
      }
    }
  }

  .clsFormGroup {
    padding-bottom: 25px;

    .clsFormLabel {
      font-size: 16px;
      font-weight: 600;
      line-height: 16px;
      text-align: left;
      color: #3C4654;
      margin-bottom: 8px;
    }

    .clsFormControl {
      border-radius: 12px;
      border: solid 1px #DFE1E4;
      background-color: #fff;
      font-size: 16px;
      font-weight: 500;
      line-height: 16px;
      text-align: left;
      color: #3C4654;
      box-shadow: none;
      padding: 11px 13px;
      width: 100%;

      &::placeholder {
        color: #99A0AA;
      }
    }

    .text_primary{
      color: #128292;
    }

    .clsFormSelect {
      option:first-child {
        color: #99A0AA;
      }

      appearance: none;
      -webkit-appearance: none;
      -moz-appearance: none;
      width: 100%;
      background-image: url('assets/images/account-page/down-arrow.svg');
      background-repeat: no-repeat;
      background-position: right 10px center;
      background-size: 12px 12px;
      padding: 13px 13px;
    }

    .clsInputGroup {
      position: relative;
      display: flex;
      flex-wrap: wrap;
      align-items: stretch;
      width: 100%;

      .clsFormControl {
        flex: 1;

        &:first-child {
          border-top-right-radius: 0px;
          border-bottom-right-radius: 0px;
          margin-right: 1px;
        }

        &:last-child {
          border-top-left-radius: 0px;
          border-bottom-left-radius: 0px;
          margin-left: 1px;
        }
      }
    }

    .clsInputCard {
      position: absolute;
      right: 13px;
      top: 50%;
      transform: translateY(-50%);
    }
  }