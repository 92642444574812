@import "./../../../app/variables.scss";

.accordion {
  margin-top: 15px;

  &.opened {
    > .body {
      display: block;
    }

    > .header {
      .arrow {
        transform: rotate(90deg);
      }
    }
  }

  &.large {
    > .header {
      .caption {
        padding-left: 13px;
        font-size: 14px;
        font-weight: 500;
        color: $color-grey;
      }
    }

    > .body {
      padding: 15px 21px 10px;
    }
  }

  &.right {
    margin-top: 20px;

    > .header {
      .caption {
        padding-left: 0;
        padding-right: 13px;
      }

      .arrow {
        float: right;
      }
    }
  }
}

.arrow {
  width: 8px;
  height: 14px;
}

.header {
  display: inline-block;
  vertical-align: top;
  cursor: pointer;
}

.caption {
  display: inline-block;
  vertical-align: top;
  padding-left: 7px;
  font-size: 12px;
  line-height: 14px;
  color: $color-blue-darkest;
}

.body {
  display: none;
}
