@import '~app/variables';

.users {
  color: $color-grey;

  .noUsers {
    font-style: italic;
  }

  .user {
    position: relative;
    display: flex;
    align-items: center;
    padding: 20px;
    border: 1px solid $color-grey-lighter;
    border-bottom-style: none;

    &:nth-child(odd) {
      background-color: #f3f3f3;
    }

    &:last-child {
      border-bottom-style: solid;
    }

    .name {
      flex: 1;
      padding-left: 20px;

      :first-child {
        font-weight: bold;
      }

      .type {
        font-style: italic;
      }
    }

    .buttons button {
      width: auto;
    }

    .expiration {
      position: absolute;
      bottom: 10px;
      right: 10px;
      font-style: italic;
      color: $color-red;
    }
  }
}
